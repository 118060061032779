import React, { useContext, useEffect, useState, useCallback } from 'react';
import io from 'socket.io-client';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { GeoTextContext } from './contexts/GeoTextContext';
import { IGeoTextContext } from './interfaces';
import { useParams, useHistory } from 'react-router';

import(`assets/${process.env.REACT_APP_BRAND}/styles/geotext/MapComponent.scss`);

const DEFAULT_COORDS = { lat: 18.42139, lng: -66.073795 };
let socket: SocketIOClient.Socket;

const MapComponent: React.FC = () => {
	const { dictionary } = useContext(GeoTextContext) as IGeoTextContext;
	const { roomId } = useParams();

	const history = useHistory();
	const [geo] = useState(navigator.geolocation);
	const [coords, setCoords] = useState(DEFAULT_COORDS);

	const dragEnd = ({ latLng: { lat, lng } }: any): void => {
		sendGeoLocation(lat(), lng());
	};

	const fetchAddress = async function (lat: number, lng: number): Promise<string> {
		const response = await fetch(`${process.env.REACT_APP_HELIOS_API}/maps/directions/getAddress`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				coords: `${lat},${lng}`,
			}),
		});
		const res = await response.json();
		return res.status ? res.data : '';
	};

	const sendGeoLocation = useCallback(
		async (lat: number, lng: number): Promise<void> => {
			setCoords({ lat, lng });
			const address: string = await fetchAddress(lat, lng);
			socket.emit('gps', { lat, lng, address, dashboardId: roomId });
		},
		[roomId]
	);

	const confirmLocation = () => {
		socket.close();
		history.push('/geo/confirmation');
	};

	useEffect(() => {
		socket = io(`${process.env.REACT_APP_HELIOS_API}/map`, { transports: ['websocket', 'polling'] });
		if (geo) {
			geo.getCurrentPosition(
				({ coords: { latitude: lat, longitude: lng } }) => {
					sendGeoLocation(lat, lng);
				},
				(error) => {
					console.error(error);
					if (socket) socket.close();
					history.push('/geo/error/location');
				}
			);
		}
	}, [geo, history, sendGeoLocation]);

	return (
		<div className="map">
			<img className="logo" src={`/assets/${process.env.REACT_APP_BRAND}/imgs/logo_color.png`} alt="Company Logo" />
			<p className="message" id="advise">{dictionary?.map?.advise}</p>
			<LoadScript id="script-loader" googleMapsApiKey={process.env.REACT_APP_GMAPS_KEY}>
				<GoogleMap
					id="map"
					zoom={17}
					center={{
						lat: coords.lat,
						lng: coords.lng,
					}}
					options={{ fullscreenControl: false, disableDefaultUI: true }}
				>
					<Marker
						draggable={true}
						position={{
							lat: coords.lat,
							lng: coords.lng,
						}}
						onDragEnd={dragEnd}
					/>
				</GoogleMap>
			</LoadScript>
			<button className="button" onClick={confirmLocation}>
				{dictionary?.map?.confirm}
			</button>
		</div>
	);
};

export default MapComponent;

import React, { createContext, useReducer, useEffect } from 'react';
import { geoTextReducer } from '../reducers/geoTextReducer';
import { loadDictionary } from '../actions';
import { IGeoTextContext } from '../interfaces';

export const GeoTextContext = createContext({});

export const GeoTextState = (props: any) => {
	const dispatch = (action: any) => {
		_dispatch(action);
	};

	const data: IGeoTextContext = {
		dictionary: {},
		dispatch,
	};

	const [state, _dispatch] = useReducer(geoTextReducer, data);

	useEffect(() => {
		import(`assets/${process.env.REACT_APP_BRAND}/dictionary.json`).then(file => {
			dispatch(loadDictionary(file.default));
		});
	}, [state.dictionary]);

	return <GeoTextContext.Provider value={state}>{props.children}</GeoTextContext.Provider>;
};

export const { Consumer } = GeoTextContext;

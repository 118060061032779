import React, { useContext, useState } from 'react';
import { GeoTextContext } from './contexts/GeoTextContext';
import { IGeoTextContext } from './interfaces';
import { useParams, useHistory } from 'react-router';

import(`assets/${process.env.REACT_APP_BRAND}/styles/geotext/PermissionComponent.scss`);

const PermissionComponent: React.FC = () => {
	const { dictionary } = useContext(GeoTextContext) as IGeoTextContext;
	const { roomId } = useParams();
	const history = useHistory();
	const [geo] = useState(navigator.geolocation);

	const askForPermission = () => {
		if (geo) {
			geo.getCurrentPosition(
				() => {
					history.push(`/geo/map/${roomId}`);
				},
				(error) => {
					console.error(error);
					history.push('/geo/error/location');
				}
			);
		}
	};

	return (
		<div className="permission">
			<img className="pin-logo" src={`/assets/${process.env.REACT_APP_BRAND}/imgs/pin_color.png`} alt="Pin Logo" />
			<p className="message">{dictionary?.permission?.message}</p>
			<button className="button" onClick={askForPermission}>
				{dictionary?.permission?.accept}
			</button>
		</div>
	);
};

export default PermissionComponent;

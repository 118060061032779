import React, { useEffect, useState, useContext, useCallback } from 'react';
import { get } from 'lodash';

import LocationFormComponent from './LocationFormComponent';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { ServiceRequestContext } from './contexts/ServiceRequestContext';
import { IServiceRequestContext } from './interfaces';
import { updateLocation } from './actions';

import(`assets/${process.env.REACT_APP_BRAND}/styles/servicerequest/LocationContainer.scss`);

const LocationContainer: React.FC = () => {
	const context = useContext(ServiceRequestContext) as IServiceRequestContext;

	const [geo] = useState(navigator.geolocation);
	const location = { lat: 0, lng: 0 };

	const _fetchAddress = async function (lat: number, lng: number) {
		const response = await fetch(`${process.env.REACT_APP_HELIOS_API}/maps/directions/getAddress`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				coords: `${lat},${lng}`,
			}),
		});
		const res = await response.json();

		if (res.status) {
			const address = res.data;
			context.dispatch(updateLocation({ lat, lng, address }));
		}
	};

	const fetchAddress = useCallback(_fetchAddress, []);

	const dragEnd = ({ latLng: { lat, lng } }: any) => {
		fetchAddress(lat(), lng());
	};

	useEffect(() => {
		if (geo) {
			geo.getCurrentPosition(
				({ coords: { latitude: lat, longitude: lng } }) => {
					fetchAddress(lat, lng);
				},
				(error) => {
					console.error(error);
				}
			);
		}
	}, [fetchAddress, geo, location.lat, location.lng]);

	return (
		<div className="location">
			<LoadScript id="script-loader" googleMapsApiKey={process.env.REACT_APP_GMAPS_KEY}>
				<GoogleMap
					id="map"
					zoom={17}
					center={{
						lat: get(context, 'request.location.lat'),
						lng: get(context, 'request.location.lng'),
					}}
					options={{ fullscreenControl: false, disableDefaultUI: true }}
				>
					<Marker
						draggable={true}
						position={{
							lat: get(context, 'request.location.lat'),
							lng: get(context, 'request.location.lng'),
						}}
						onDragEnd={dragEnd}
					/>
				</GoogleMap>
			</LoadScript>
			<LocationFormComponent fetchAddress={fetchAddress} />
		</div>
	);
};

export default LocationContainer;

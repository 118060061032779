import { IContact, ILocation } from '../interfaces/';
import { DEFAULT_LOCATION } from 'servicerequest/contexts/ServiceRequestContext';

export const LOAD_SITUATIONS = 'LOAD_SITUATIONS';
export const LOAD_QUESTIONS = 'LOAD_QUESTIONS';
export const LOAD_DICTIONARY = 'SET_DICTIONARY';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_SITUATION = 'UPDATE_SITUATION';
export const SET_FINAL_SITUATION = 'SET_FINAL_SITUATION';
export const CLEAR_REQUEST = 'CLEAR_REQUEST';

export const loadDictionary = (dictionary: any) => {
	return { type: LOAD_DICTIONARY, dictionary };
};

export const loadQuestions = (questions: any) => {
	return { type: LOAD_QUESTIONS, questions };
};

export const loadSituations = (situations: any) => {
	return { type: LOAD_SITUATIONS, situations };
};

export const updateContact = (contact: IContact) => {
	return { type: UPDATE_CONTACT, contact };
};

export const updateLocation = (location: ILocation) => {
	return { type: UPDATE_LOCATION, location };
};

export const updateSituation = (situation: string) => {
	return { type: UPDATE_SITUATION, situation };
};

export const setFinalSituation = (finalSituation: string) => {
	return { type: SET_FINAL_SITUATION, finalSituation };
};

export const clearRequest = () => {
	return { type: CLEAR_REQUEST, request: { location: DEFAULT_LOCATION } };
};

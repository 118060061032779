import React, { useContext } from 'react';
import { find, get } from 'lodash';

import { ServiceRequestContext } from './contexts/ServiceRequestContext';
import { IServiceRequestContext } from './interfaces';
import { useHistory } from 'react-router';

import(`assets/${process.env.REACT_APP_BRAND}/styles/servicerequest/ReviewComponent.scss`);

const ReviewComponent: React.FC = () => {
	const context = useContext(ServiceRequestContext) as IServiceRequestContext;
	const history = useHistory();
	const { dictionary, situations } = context;

	const situationLabel = find(situations, {
		name: get(context, 'request.finalSituation'),
	})?.longLabel;

	const submit = async () => {
		const body = {
			account_id: process.env.REACT_APP_ACCOUNT_SFID,
			situation: get(context, 'request.finalSituation'),
			clientName: get(context, 'request.contact.firstName'),
			clientLastName: get(context, 'request.contact.lastName'),
			clientPhone1: get(context, 'request.contact.phone'),
			vehPlate: get(context, 'request.contact.plate'),
			sitAddress: get(context, 'request.location.address'),
			sitLatitude: get(context, 'request.location.lat'),
			sitLongitude: get(context, 'request.location.lng'),
		};
		const response = await fetch(`${process.env.REACT_APP_HELIOS_API}/api/v1/service-requests`, {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(body),
		});
		const res = await response.json();
		if (res.status) history.push('/request/confirmation');
	};

	return (
		<div className="review">
			<p className="title">{get(dictionary, 'review.title')}</p>
			<div className="container">
				<label className="child">{get(dictionary, 'review.service')}</label>
				<p className="child">{situationLabel}</p>
				<label className="child">{get(dictionary, 'review.name')}</label>
				<p className="child">{`${get(context, 'request.contact.firstName')} ${get(
					context,
					'request.contact.lastName'
				)}`}</p>
				<label className="child">{get(dictionary, 'review.phone')}</label>
				<p className="child">{get(context, 'request.contact.phone')}</p>
				<label className="child">{get(dictionary, 'review.plate')}</label>
				<p className="child">{get(context, 'request.contact.plate')}</p>
				<label className="child">{get(dictionary, 'review.location')}</label>
				<p className="child">{get(context, 'request.location.address')}</p>
			</div>
			<div className="buttons-container">
				<button className="button" onClick={submit}>
					{get(dictionary, 'review.confirm')}
				</button>
				<button className="button" onClick={() => history.push('/request/location')}>
					{get(dictionary, 'review.edit')}
				</button>
			</div>
		</div>
	);
};

export default ReviewComponent;

import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { ServiceRequestContainer } from './servicerequest/ServiceRequestContainer';
import { GeoTextContainer } from './geotext/GeoTextContainer';

import(`assets/${process.env.REACT_APP_BRAND}/styles.scss`);

const App: React.FC = () => {
	return (
		<div className="App">
			<Router>
				<Switch>
					<Route path="/request" component={ServiceRequestContainer} />
					<Route path="/geo" component={GeoTextContainer} />
					<Redirect from="/" to="/request" />
				</Switch>
			</Router>
		</div>
	);
};

export default App;

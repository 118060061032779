import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { get } from 'lodash';

import { ServiceRequestContext } from './contexts/ServiceRequestContext';
import { IServiceRequestContext, ILocationFormProps } from './interfaces';

import { updateLocation } from './actions';

import(`assets/${process.env.REACT_APP_BRAND}/styles/servicerequest/LocationFormComponent.scss`);

const LocationFormComponent: React.FC<ILocationFormProps> = props => {
	const context = useContext(ServiceRequestContext) as IServiceRequestContext;
	const history = useHistory();
	const { dictionary } = context;

	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');

	const confirmLocation = () => {
		history.push('/request/contact');
	};

	const fetchCoordinates = async () => {
		const response = await fetch(`${process.env.REACT_APP_HELIOS_API}/maps/directions/getCoordinates`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ address: `${address} ${city}` }),
		});
		const res = await response.json();

		if (res.status) {
			context.dispatch(updateLocation(res.data));
			props.fetchAddress(res.data.lat, res.data.lng);
		}
	};

	return (
		<div className="location-form">
			<p className="question">{get(dictionary, 'location.question')}</p>
			<div className="options">
				<button className="option" onClick={confirmLocation}>
					{get(dictionary, 'general.yes')}
				</button>
			</div>
			<p className="message">{get(dictionary, 'location.write_address')}</p>
			<div className="container">
				<div className="input-container child">
					<input
						type="text"
						value={address}
						placeholder={get(dictionary, 'location.address_placeholder')}
						onChange={({ target: { value } }) => setAddress(value)}
					/>
					<div className="input-icons">
						<img
							className="search-icon"
							src={`/assets/${process.env.REACT_APP_BRAND}/imgs/search_icon.png`}
							alt="location icon"
						/>
						<img
							className="location-icon"
							src={`/assets/${process.env.REACT_APP_BRAND}/imgs/location_icon.png`}
							alt="location icon"
						/>
					</div>
				</div>

				<input
					className="child"
					type="text"
					value={city}
					placeholder={get(dictionary, 'location.city_placeholder')}
					onChange={({ target: { value } }) => setCity(value)}
				/>
				<button className="option child" onClick={fetchCoordinates}>
					{get(dictionary, 'location.search')}
				</button>
			</div>
		</div>
	);
};

export default LocationFormComponent;

import React from 'react';
import { Route } from 'react-router-dom';

import { MenuContainer } from './MenuContainer';
import LocationContainer from './LocationContainer';
import QuestionsContainer from './QuestionsContainer';
import ContactFormContainer from './ContactFormContainer';
import ReviewComponent from './ReviewComponent';
import ConfirmationComponent from './ConfirmationComponent';
import { TopbarComponent } from './TopbarComponent';
import { FooterComponent } from './FooterComponent';
import { ServiceRequestState } from './contexts/ServiceRequestContext';

export const ServiceRequestContainer: React.FC = (): JSX.Element => {
	return (
		<ServiceRequestState>
			<TopbarComponent />
			<Route exact path="/request" component={MenuContainer} />
			<Route exact path="/request/location" component={LocationContainer} />
			<Route exact path="/request/contact" component={ContactFormContainer} />
			<Route exact path="/request/questions" component={QuestionsContainer} />
			<Route exact path="/request/review" component={ReviewComponent} />
			<Route exact path="/request/confirmation" component={ConfirmationComponent} />
			<FooterComponent />
		</ServiceRequestState>
	);
};

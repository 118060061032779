import React, { useContext } from 'react';
import { GeoTextContext } from './contexts/GeoTextContext';
import { IGeoTextContext } from './interfaces';
import { useHistory } from 'react-router';

import(`assets/${process.env.REACT_APP_BRAND}/styles/geotext/LocationErrorComponent.scss`);

const ErrorComponent: React.FC = () => {
	const { dictionary } = useContext(GeoTextContext) as IGeoTextContext;
	const history = useHistory();
	const isForLocation = history.location.pathname.includes('location');
	const error = isForLocation ? dictionary?.locationError : dictionary?.roomIdError;
	return (
		<div className="location-error">
			<p className="error-message">{error?.errorMessage}</p>
			<img className="image" src={`/assets/${process.env.REACT_APP_BRAND}/imgs/error_screen.svg`} alt="" />
			<p className="message">{error?.message}</p>
		</div>
	);
};

export default ErrorComponent;

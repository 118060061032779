import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import InputMask from 'react-input-mask';
import { get } from 'lodash';

import { updateContact, setFinalSituation } from './actions';

import { ServiceRequestContext } from './contexts/ServiceRequestContext';
import { IServiceRequestContext } from './interfaces';

import(`assets/${process.env.REACT_APP_BRAND}/styles/servicerequest/ContactFormContainer.scss`);

const ContactFormContainer: React.FC = () => {
	const context = useContext(ServiceRequestContext) as IServiceRequestContext;
	const history = useHistory();
	const { dictionary } = context;

	const getPhoneMask = () => {
		switch (process.env.REACT_APP_BRANCH as string) {
			case 'Costa Rica':
			case 'Panama':
				return '99999999';
			case 'Puerto Rico':
			default:
				return '999-999-9999';
		}
	};

	const [firstName, setFirstName] = useState(get(context, 'request.contact.firstName', ''));
	const [lastName, setLastName] = useState(get(context, 'request.contact.lastName', ''));
	const [phone, setPhone] = useState(get(context, 'request.contact.phone', ''));
	const [phoneReadOnly, setPhoneReadOnly] = useState(true);
	const [plate, setPlate] = useState(get(context, 'request.contact.plate', ''));
	const [errors, setErrors] = useState([] as string[]);

	const setContact = () => {
		if (validFields()) {
			context.dispatch(updateContact({ firstName, lastName, phone, plate }));
			if (context.questions) history.push('/request/questions');
			else {
				context.dispatch(setFinalSituation(get(context, 'request.situation')));
				history.push('/request/review');
			}
		}
	};

	const validFields = () => {
		const branch = process.env.REACT_APP_BRANCH as string;
		const maskLength = ['Panama', 'Costa Rica'].includes(branch) ? 7 : 12;
		const _errors: string[] = [];

		if (!firstName) _errors.push(get(dictionary, 'contact.errors.firstName'));
		if (!lastName) _errors.push(get(dictionary, 'contact.errors.lastName'));
		if (!phone) _errors.push(get(dictionary, 'contact.errors.phone'));
		if (phone && phone.length < maskLength) _errors.push(get(dictionary, 'contact.errors.phoneFormat'));
		if (!plate) _errors.push(get(dictionary, 'contact.errors.plate'));
		if (plate && plate.length < 6) _errors.push(get(dictionary, 'contact.errors.plateFormat'));

		setErrors([..._errors]);
		return _errors.length === 0;
	};

	const showErrors = () => {
		return (
			<div className="errors">
				<ul>
					{errors.map(error => (
						<li key={error}>{error}</li>
					))}
				</ul>
			</div>
		);
	};

	/**
	 * This is used as a workaround to an issue with autofill
	 * and phone input mask when mobile.
	 * @param event
	 */
	const removeReadOnly = (event: React.FocusEvent<HTMLInputElement>) => {
		if (phoneReadOnly) {
			event.persist();
			event.target.blur();
			setTimeout(() => {
				event.target.focus();
			}, 100);
		}
	};

	return (
		<div className="contact">
			<p className="title">{get(dictionary, 'contact.title')}</p>
			{errors.length > 0 ? showErrors() : ''}
			<div className="container">
				<label className="child" htmlFor="firstName">
					{get(dictionary, 'contact.first_name_placeholder')}
				</label>
				<input
					type="text"
					className="child"
					value={firstName}
					placeholder={get(dictionary, 'contact.first_name_placeholder')}
					onChange={({ target: { value } }) => setFirstName(value)}
				/>
				<label className="child" htmlFor="lastName">
					{get(dictionary, 'contact.last_name_placeholder')}
				</label>
				<input
					type="text"
					className="child"
					value={lastName}
					placeholder={get(dictionary, 'contact.last_name_placeholder')}
					onChange={({ target: { value } }) => setLastName(value)}
				/>
				<label className="child" htmlFor="phone">
					{get(dictionary, 'contact.phone_placeholder')}
				</label>
				<InputMask
					mask={getPhoneMask()}
					maskChar=""
					alwaysShowMask={false}
					className="child"
					readOnly={phoneReadOnly}
					onFocus={e => {
						setPhoneReadOnly(false);
						if (phoneReadOnly) removeReadOnly(e);
					}}
					value={phone}
					placeholder={get(dictionary, 'contact.phone_placeholder')}
					onChange={({ target: { value } }) => setPhone(value)}
				/>
				<label className="child" htmlFor="phone">
					{get(dictionary, 'contact.plate_placeholder')}
				</label>
				<InputMask
					mask="*******"
					maskChar=""
					alwaysShowMask={false}
					className="child"
					value={plate}
					placeholder={get(dictionary, 'contact.plate_placeholder')}
					onChange={({ target: { value } }) => setPlate(value.toUpperCase())}
				/>
				<button className="button child" onClick={setContact}>
					{get(dictionary, 'contact.confirm')}
				</button>
			</div>
		</div>
	);
};

export default ContactFormContainer;

import React, { useContext, useEffect, useCallback } from 'react';
import { get } from 'lodash';

import { ServiceRequestContext } from './contexts/ServiceRequestContext';
import { IServiceRequestContext } from './interfaces';
import { clearRequest } from './actions';

import(`assets/${process.env.REACT_APP_BRAND}/styles/servicerequest/ConfirmationComponent.scss`);

const ConfirmationComponent: React.FC = () => {
	const context = useContext(ServiceRequestContext) as IServiceRequestContext;
	const { dictionary, dispatch } = context;

	const _clearRequest = () => {
		dispatch(clearRequest());
	};
	const clearServiceRequest = useCallback(_clearRequest, []);

	useEffect(() => {
		clearServiceRequest();
	}, [clearServiceRequest]);

	return (
		<div className="confirmation">
			<p className="title">{get(dictionary, 'confirmation.title')}</p>
			<p className="message">{get(dictionary, 'confirmation.message')}</p>
			<img src={`/assets/${process.env.REACT_APP_BRAND}/imgs/checkmark.svg`} alt="checkmark" />
		</div>
	);
};

export default ConfirmationComponent;

import React from 'react';
import { Route } from 'react-router-dom';
import { GeoTextState } from './contexts/GeoTextContext';
import ErrorComponent from './ErrorComponent';
import MapComponent from './MapComponent';
import ConfirmationComponent from './ConfirmationComponent';
import PermissionComponent from './PermissionComponent';

export const GeoTextContainer: React.FC = (): JSX.Element => {
	return (
		<GeoTextState>
			<Route path="/geo/permission/:roomId" component={PermissionComponent} />
			<Route path="/geo/map/:roomId" component={MapComponent} />
			<Route path="/geo/error/location" component={ErrorComponent} />
			<Route path="/geo/error/roomid" component={ErrorComponent} />
			<Route path="/geo/confirmation" component={ConfirmationComponent} />
		</GeoTextState>
	);
};

import {
	LOAD_DICTIONARY,
	UPDATE_CONTACT,
	UPDATE_SITUATION,
	UPDATE_LOCATION,
	SET_FINAL_SITUATION,
	LOAD_QUESTIONS,
	LOAD_SITUATIONS,
	CLEAR_REQUEST,
} from '../actions';
import { IServiceRequestContext } from 'servicerequest/interfaces';

export const serviceRequestReducer = (state: IServiceRequestContext, action: any) => {
	let updatedState;
	switch (action.type) {
		case LOAD_DICTIONARY:
			return { ...state, dictionary: action.dictionary };
		case LOAD_SITUATIONS:
			return {
				...state,
				situations: action.situations,
			};
		case LOAD_QUESTIONS:
			return {
				...state,
				questions: action.questions,
			};
		case UPDATE_CONTACT:
			const { contact } = action;

			updatedState = {
				...state,
				request: { ...state.request, contact },
			};
			saveStateToLocalStorage(updatedState);
			return updatedState;
		case UPDATE_SITUATION:
			updatedState = {
				...state,
				request: { ...state.request, situation: action.situation },
			};

			saveStateToLocalStorage(updatedState);
			return updatedState;
		case UPDATE_LOCATION:
			updatedState = {
				...state,
				request: {
					...state.request,
					location: { ...state.request.location, ...action.location },
				},
			};
			saveStateToLocalStorage(updatedState);
			return updatedState;
		case SET_FINAL_SITUATION:
			updatedState = {
				...state,
				request: {
					...state.request,
					finalSituation: action.finalSituation,
				},
			};
			saveStateToLocalStorage(updatedState);
			return updatedState;
		case CLEAR_REQUEST:
			updatedState = {
				...state,
				request: action.request,
			};
			saveStateToLocalStorage(updatedState);
			return updatedState;
		default:
			return state;
	}
};

const saveStateToLocalStorage = (state: any) => {
	localStorage.setItem('serviceRequestState', JSON.stringify(state));
};
export const loadStateFromLocalStorage = () => {
	const state = localStorage.getItem('serviceRequestState');
	if (!state) return {};
	return JSON.parse(state);
};

export const deleteStateFromLocalStorage = () => {
	const state = localStorage.getItem('serviceRequestState');
	if (!state) return {};
	return JSON.parse(state);
};

import { IGeoTextContext } from '../interfaces';

import { LOAD_DICTIONARY } from '../actions';

export const geoTextReducer = (state: IGeoTextContext, action: any) => {
	switch (action.type) {
		case LOAD_DICTIONARY:
			return { ...state, dictionary: action.dictionary };
		default:
			return { ...state };
	}
};

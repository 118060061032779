import React, { useContext } from 'react';
import { get } from 'lodash';
import { ServiceRequestContext } from './contexts/ServiceRequestContext';

import(`assets/${process.env.REACT_APP_BRAND}/styles/servicerequest/TopbarComponent.scss`);

export const TopbarComponent: React.FC = () => {
	const { dictionary }: any = useContext(ServiceRequestContext);
	return (
		<div className="topbar">
			<p className="title">{get(dictionary, 'topbar.title')}</p>
		</div>
	);
};

import React, { createContext, useReducer, useEffect } from 'react';
import { serviceRequestReducer, loadStateFromLocalStorage } from '../reducers/servicerRequestReducer';
import { loadDictionary, loadSituations, loadQuestions } from '../actions';
import { IServiceRequestContext } from '../interfaces';

export const DEFAULT_LOCATION = { lat: 18.42139, lng: -66.073795 };

export const ServiceRequestContext = createContext({});

export const ServiceRequestState = (props: any) => {
	const dispatch = (action: any) => {
		_dispatch(action);
	};
	const backedUpState = loadStateFromLocalStorage();

	const data: IServiceRequestContext = {
		request: { location: DEFAULT_LOCATION },
		dictionary: {},
		situations: [],
		questions: [],
		dispatch,
		...backedUpState,
	};

	const [state, _dispatch] = useReducer(serviceRequestReducer, data);

	useEffect(() => {
		import(`assets/${process.env.REACT_APP_BRAND}/dictionary.json`).then((file) => {
			dispatch(loadDictionary(file.default));
		});
		if (process.env.REACT_APP_HAS_QUESTIONS === 'true') {
			import(`assets/${process.env.REACT_APP_BRAND}/questions.json`).then((file) => {
				if (file) dispatch(loadQuestions(file.default));
			});
		} else {
			dispatch(loadQuestions(null));
		}

		import(`assets/${process.env.REACT_APP_BRAND}/situations.json`).then((file) => {
			if (file) dispatch(loadSituations(file.default));
		});
	}, [state.dictionary]);

	return <ServiceRequestContext.Provider value={state}>{props.children}</ServiceRequestContext.Provider>;
};

export const { Consumer } = ServiceRequestContext;

import React, { useContext } from 'react';

import { GeoTextContext } from './contexts/GeoTextContext';
import { IGeoTextContext } from './interfaces';

import(`assets/${process.env.REACT_APP_BRAND}/styles/geotext/ConfirmationComponent.scss`);

const ConfirmationComponent: React.FC = () => {
	const context = useContext(GeoTextContext) as IGeoTextContext;
	const { dictionary } = context;

	return (
		<div className="geo-confirmation">
			<img className="logo" src={`/assets/${process.env.REACT_APP_BRAND}/imgs/logo_white.png`} alt="Checkmark Logo" />
			<img className="check" src={`/assets/${process.env.REACT_APP_BRAND}/imgs/checkmark.png`} alt="Checkmark Logo" />
			<p className="message">{dictionary?.locationConfirmation?.message}</p>
		</div>
	);
};

export default ConfirmationComponent;

import React, { useContext, useCallback, useEffect } from 'react';
import { get } from 'lodash';

import { SituationComponent } from './SituationComponent';
import { ServiceRequestContext } from './contexts/ServiceRequestContext';
import { IServiceRequestContext } from './interfaces';
import { clearRequest } from './actions';

import(`assets/${process.env.REACT_APP_BRAND}/styles/servicerequest/MenuContainer.scss`);

export const MenuContainer: React.FC = () => {
	const { dictionary, situations, dispatch } = useContext(ServiceRequestContext) as IServiceRequestContext;
	const _clearRequest = () => {
		dispatch(clearRequest());
	};
	const clearServiceRequest = useCallback(_clearRequest, []);

	useEffect(() => {
		clearServiceRequest();
	}, [clearServiceRequest]);

	return (
		<div className="home">
			<p className="title">{get(dictionary, 'home.title')}</p>
			<div className="situations">
				{situations.map((situation) => {
					return situation.display ? <SituationComponent situation={situation} key={situation.name} /> : '';
				})}
				<div className="box-placeholder"></div>
				<div className="box-placeholder"></div>
				<div className="box-placeholder"></div>
				<div className="box-placeholder"></div>
			</div>
		</div>
	);
};

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

const PATHS_TO_IGNORE = ['/', '/request', '/request/', '/request/confirmation', '/request/confirmation/'];

import(`assets/${process.env.REACT_APP_BRAND}/styles/servicerequest/FooterComponent.scss`);

export const FooterComponent: React.FC = () => {
	const [showButton, setShowButton] = useState(false);
	const history = useHistory();

	history.listen(({ pathname }) => {
		setShowButton(!PATHS_TO_IGNORE.includes(pathname));
	});

	useEffect(() => {
		setShowButton(!PATHS_TO_IGNORE.includes(window.location.pathname));
	}, []);

	return (
		<div className="footer">
			{showButton ? (
				<button onClick={() => window.history.back()}>
					<img src={`/assets/${process.env.REACT_APP_BRAND}/imgs/back_arrow.svg`} alt="" />
					<span>Atrás</span>
				</button>
			) : (
				''
			)}
		</div>
	);
};

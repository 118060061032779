import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { updateSituation } from './actions';
import { ServiceRequestContext } from './contexts/ServiceRequestContext';
import { IServiceRequestContext, ISituation } from './interfaces';

interface ISituationProps {
	situation: ISituation;
}

export const SituationComponent: React.FC<ISituationProps> = props => {
	const context = useContext(ServiceRequestContext) as IServiceRequestContext;
	const history = useHistory();
	const { situation } = props;

	const setSituation = (situation: string) => {
		context.dispatch(updateSituation(situation));
		history.push('/request/location');
	};

	return (
		<div className="situation" onClick={() => setSituation(situation.name)}>
			<img src={`/assets/${process.env.REACT_APP_BRAND}/imgs/${situation.imageName}`} alt={situation.imageName} />
			<p>{situation.label}</p>
		</div>
	);
};

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { find, findIndex, get } from 'lodash';
import { IQuestion, IQuestionProps, IServiceRequestContext, IAnswer } from './interfaces';
import { ServiceRequestContext } from './contexts/ServiceRequestContext';
import { useHistory } from 'react-router';

import { setFinalSituation } from './actions';

import(`assets/${process.env.REACT_APP_BRAND}/styles/servicerequest/QuestionsContainer.scss`);

const Question = (props: IQuestionProps) => {
	const { question, nextQuestion } = props;

	return (
		<div className="question">
			<div className="text">{question.text}</div>
			<div className="options">
				{question.answers.map((answer: IAnswer) => (
					<div className="option" onClick={() => nextQuestion(answer, question)} key={answer._id}>
						{answer.label}
					</div>
				))}
			</div>
		</div>
	);
};

const QuestionsContainer: React.FC = () => {
	const context = useContext(ServiceRequestContext) as IServiceRequestContext;
	const {
		questions: originalQuestions,
		dictionary,
		situations,
		request: { situation },
	} = context;
	const [questions, setQuestions] = useState([] as IQuestion[]);
	const history = useHistory();

	const _getFirstQuestion = () => {
		const firstQuestionId = find(situations, { name: situation })?.startingQuestion;
		if (!firstQuestionId) {
			context.dispatch(setFinalSituation(situation));
			history.replace('/request/review');
			return;
		}
		const question = find(originalQuestions, { _id: firstQuestionId });

		if (question) setQuestions([...questions, question]);
	};
	const getFirstQuestion = useCallback(_getFirstQuestion, []);

	const nextQuestion = (answer: IAnswer, currentQuestion: IQuestion) => {
		if (answer.situation) {
			context.dispatch(setFinalSituation(answer.situation));
			history.push('/request/review');
		}

		const newQuestion = find(originalQuestions, { _id: answer.leadsTo });

		const updatedQuestions = getQuestions(currentQuestion);
		updatedQuestions.forEach(q => (q.answered = true));
		if (newQuestion) setQuestions([...updatedQuestions, newQuestion]);
	};

	const getQuestions = (question: IQuestion): IQuestion[] => {
		if (!question.answered) return [...questions];

		const index: number = findIndex(questions, {
			_id: question._id,
		});

		return questions.slice(0, index + 1);
	};

	useEffect(() => {
		getFirstQuestion();
	}, [getFirstQuestion]);

	return (
		<div className="questions">
			<p className="title">{get(dictionary, 'questions.title')}</p>
			{questions.map(question => (
				<Question question={question} nextQuestion={nextQuestion} key={question._id} />
			))}
		</div>
	);
};

export default QuestionsContainer;
